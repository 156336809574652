// Block editor styles
// Block: "core/list"

@mixin block-core-list {
	ul,
	ol {
		margin-top: 0;
		margin-left: 10px;
		padding: 0 0 0 20px;
	}

	ul {
		list-style-type: disc;
	}

	li {
		margin-top: 5px;
		margin-bottom: 0;
		line-height: 1.4;

		&:first-child {
			margin-top: 0;
		}
	}
}
