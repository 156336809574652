// Forms
// ==========================================================================

@mixin content-forms {
	//
	// 1. Change the font styles in all browsers (opinionated).
	// 2. Remove the margin in Firefox and Safari.
	//

	button,
	input,
	optgroup,
	select,
	textarea {
		font-family: inherit; // 1
		font-size: 100%; // 1
		line-height: 1.4; // 1
		margin: 0; // 2
	}


	//
	// Show the overflow in IE.
	// 1. Show the overflow in Edge.
	//

	button,
	input { // 1
		overflow: visible;
	}

	//
	// Remove the inheritance of text transform in Edge, Firefox, and IE.
	// 1. Remove the inheritance of text transform in Firefox.
	//

	button,
	select { // 1
		text-transform: none;
	}

	//
	// 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
	//		controls in Android 4.
	// 2. Correct the inability to style clickable types in iOS and Safari.
	//

	button,
	html [type="button"], // 1
	[type="reset"],
	[type="submit"] {
		-webkit-appearance: button; // 2
	}

	//
	// Remove the inner border and padding in Firefox.
	//

	button::-moz-focus-inner,
	[type="button"]::-moz-focus-inner,
	[type="reset"]::-moz-focus-inner,
	[type="submit"]::-moz-focus-inner {
		border-style: none;
		padding: 0;
	}

	//
	// Restore the focus styles unset by the previous rule.
	//

	button:-moz-focusring,
	[type="button"]:-moz-focusring,
	[type="reset"]:-moz-focusring,
	[type="submit"]:-moz-focusring {
		outline: 1px dotted ButtonText;
	}

	select,
	textarea,
	[type="text"],
	[type="search"],
	[type="password"],
	[type="email"],
	[type="url"],
	[type="tel"],
	[type="number"] {
		width: 100%;
		max-width: 500px;
		padding: $base-input-text-padding;
		border: $base-input-text-border;
		border-radius: 3px;
	}

	[type="number"] {
		width: auto;
		max-width: 100%;
	}

	button,
	[type="button"],
	[type="submit"],
	[type="reset"] {
		padding: $base-button-padding;
		color: $base-text-color;
		font-weight: bold;
		border: 1px solid $base-text-color;
		cursor: pointer;
		@include pwd-fontsize(18);
	}

	button,
	textarea,
	[type="text"],
	[type="search"],
	[type="password"],
	[type="email"],
	[type="url"],
	[type="button"],
	[type="submit"],
	[type="reset"],
	[type="tel"],
	[type="number"] {
		border-radius: 3px;
	}

	select {
		background-image: url(#{$base-image-dir}select-arrow.svg);
		background-repeat: no-repeat;
		background-position: calc(100% - 20px) 50%;
		background-size: 20px;
		background-color: #F1F1F1;
		-webkit-appearance: none; // 2
		//		border-right: 55px solid $theme-color-1;
	}

	//
	// Remove the default vertical scrollbar in IE.
	//

	textarea {
		overflow: auto;
	}

	//
	// 2. Remove the padding in IE 10-.
	//

	[type="checkbox"],
	[type="radio"] {
		padding: 0; // 2
	}

	//
	// Correct the cursor style of increment and decrement buttons in Chrome.
	//

	[type="number"]::-webkit-inner-spin-button,
	[type="number"]::-webkit-outer-spin-button {
		height: auto;
	}

	//
	// 2. Correct the outline style in Safari.
	//

	[type="search"] {
		-webkit-appearance: none;
		outline-offset: -2px; // 2

		// Remove the inner padding and cancel buttons in Chrome and Safari on macOS.

		&::-webkit-search-cancel-button,
		&::-webkit-search-decoration {
			-webkit-appearance: none;
		}
	}

	//
	// 1. Correct the inability to style clickable types in iOS and Safari.
	// 2. Change font properties to `inherit` in Safari.
	//

	::-webkit-file-upload-button {
		-webkit-appearance: button; // 1
		font: inherit; // 2
	}

	//
	// Set correct opacity for placeholder text in Firefox
	//

	* {
		&:-moz-placeholder {
			opacity: 1;
		}

		&::-moz-placeholder {
			opacity: 1;
		}
	}

	@media screen {
		button,
		[type="button"],
		[type="submit"],
		[type="reset"] {
			color: $base-button-color;
			background: $base-button-background;
			border: $base-button-border;
		}
	}
}
