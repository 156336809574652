// PWD custom breakpoint mixin
// Allows to define which grid breakpoint is used for print media.
//
// Media of at least the minimum breakpoint width. No query for the smallest breakpoint.
// Makes the @content apply to the given breakpoint and wider.

@mixin pwd-media-breakpoint-up($name, $breakpoints: $grid-breakpoints, $print-breakpoint-name: $grid-print-breakpoint) {
	$min: breakpoint-min($name, $breakpoints);

	@if $min and $name == $print-breakpoint-name {
		@media only print, screen and (min-width: $min) {
			@content;
		}
	} @else if $min {
		@media (min-width: $min) {
			@content;
		}
	} @else {
		@content;
	}
}

// PWD custom framework grid generation
//
// Used only by Bootstrap to generate the correct number of grid classes given
// any value of `$grid-columns`.

@mixin pwd-make-grid-columns($columns: $grid-columns, $gutter: $grid-gutter-width, $breakpoints: $grid-breakpoints) {
	// Common properties for all breakpoints
	.col,
	[class*="col-"] {
		position: relative;
		width: 100%;
		min-height: 1px; // Prevent columns from collapsing when empty
		padding-right: ($gutter * 0.5);
		padding-left: ($gutter * 0.5);
	}

	@each $breakpoint in map-keys($breakpoints) {
		$infix: breakpoint-infix($breakpoint, $breakpoints);

		// Allow columns to stretch full width below their breakpoints
		// @for $i from 1 through $columns {
		//	.col#{$infix}-#{$i} {
		//		@extend %grid-column;
		//	}
		//}
		//.col#{$infix},
		//.col#{$infix}-auto {
		//	@extend %grid-column;
		//}

		@include pwd-media-breakpoint-up($breakpoint, $breakpoints) {
			// Provide basic `.col-{bp}` classes for equal-width flexbox columns
			.col#{$infix} {
				flex-basis: 0;
				flex-grow: 1;
				max-width: 100%;
			}
			//.col#{$infix}-auto {
			//	flex: 0 0 auto;
			//	width: auto;
			//	max-width: none; // Reset earlier grid tiers
			// }

			@for $i from 1 through $columns {
				.col#{$infix}-#{$i} {
					@include make-col($i, $columns);
				}
			}

			//@if $infix == "-md" {
			//	.offset#{$infix}-1 {
			//		@include make-col-offset(1, $columns);
			//	}
			//}

			@if $infix == "-lg" {
				.offset#{$infix}-1 {
					@include make-col-offset(1, $columns);
				}
			}

			// .order#{$infix}-first {
			//	order: -1;
			//}

			//@for $i from 1 through $columns {
			//	.order#{$infix}-#{$i} {
			//		order: $i;
			//	}
			// }

			// `$columns - 1` because offsetting by the width of an entire row isn't possible
			//@for $i from 0 through ($columns - 1) {
			//	@if not ($infix == "" and $i == 0) { // Avoid emitting useless .offset-0
			//		.offset#{$infix}-#{$i} {
			//			@include make-col-offset($i, $columns);
			//		}
			//	}
			// }
		}
	}
}

// Row
//
// Rows contain and clear the floats of your columns.

@if $enable-grid-classes {
	.row {
		@include make-row();
	}

	// Remove the negative margin from default .row, then the horizontal padding
	// from all immediate children columns (to prevent runaway style inheritance).
	//.no-gutters {
	//	margin-right: 0;
	//	margin-left: 0;

	//	> .col,
	//	> [class*="col-"] {
	//		padding-right: 0;
	//		padding-left: 0;
	//	}
	//}
}

// Columns
//
// Common styles for small and large grid columns

@if $enable-grid-classes {
	@include pwd-make-grid-columns();
}
