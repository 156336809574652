// Fluid container
//
// Utilizes the mixin meant for fixed width containers, but with 100% width for
// fluid, full width layouts.

@if $enable-grid-classes {
	.container-fluid,
	.container-fluid--xl {
		width: 100%;
		max-width: map-get($container-max-widths, $grid-default-breakpoint);
	}

	.container-fluid {
		@include make-container();

		&--xl {
			padding-left: 0;
			padding-right: 0;
		}
	}

	@media (min-width: 1px) {
		.container-fluid {
			max-width: map-get($container-max-widths, "xl");

			&--xl {
				max-width: $base-container-max-width;
			}
		}
	}
}
