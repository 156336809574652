// Block editor styles
// Block: "core/heading"

@mixin block-core-heading {
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		color: $heading-color;
		font-style: $heading-font-style;
		line-height: $heading-line-height;

		&:first-child {
			margin-top: 0;
		}
	}

	h1 {
		@include pwd-heading-style(1, margin);
		@include pwd-heading-style(1, font-weight);
	}

	h2,
	h3,
	h4,
	h5,
	h6 {
		font-weight: $heading-font-weight;
		@include pwd-heading-style(2, margin);
	}

	@each $heading in $heading-font-sizes {
		$level: index($heading-font-sizes, $heading);

		h#{$level} {
			@if $level <= length($heading-font-sizes-xs) {
				$min_font: nth($heading-font-sizes-xs, $level) * 1px;
				$max_font: nth($heading-font-sizes, $level) * 1px;
				@include fluid-type($base-container-min-width, map-get($container-max-widths, "xl"), $min_font, $max_font);
			} @else {
				@include pwd-fontsize(nth($heading-font-sizes, $level));
			}
		}
	}

	@include media-breakpoint-down(sm) {
		h1,
		h2,
		h3 {
			overflow-wrap: break-word;
			word-wrap: break-word;
			-webkit-hyphens: auto;
			-ms-hyphens: auto;
			-moz-hyphens: auto;
			hyphens: auto;
		}
	}
}
