// Common content styles

@import "gutenberg/block-core-list";

@mixin content-common {
	p,
	blockquote,
	hr,
	figure,
	img,
	figure,
	iframe,
	video,
	dl,
	form,
	[class*="wp-block"] {
		margin-bottom: $base-content-margin;
	}

	p,
	blockquote,
	hr,
	figure,
	dl,
	ul {
		margin-top: 0;
	}

	img,
	figure,
	iframe,
	video {
		max-width: 100%;
	}

	img {
		display: block;
		height: auto;
		border-style: none;
	}

	figure {
		margin-left: 0;
		margin-right: 0;

		img {
			display: block;
			margin-bottom: 0;
		}
	}

	a[href^="mailto"] {
		overflow-wrap: break-word;
		word-wrap: break-word;
	}

	// Embedded

	iframe {
		border: 0;
	}

	// Content areas

	.content > *:last-child,
	blockquote > *:last-child,
	p > *:last-child,
	.wp-content-block:last-child {
		margin-bottom: 0;
	}

	.content {
		@include clearfix();
	}

	// Hyphenate or break long strings
	// https://justmarkup.com/log/2015/07/dealing-with-long-words-in-css/

	.content-hyphenate {
		overflow-wrap: break-word;
		word-wrap: break-word;
		-webkit-hyphens: auto;
		-ms-hyphens: auto;
		-moz-hyphens: auto;
		hyphens: auto;
	}

	@include block-core-list();
}
