// == Theme view general structure and helper classes ==

// Common

[class*="margin-bottom"],
.wp-content-block:not(:last-child) {
	margin-bottom: $base-margin;
}

[class*="margin-top"] {
	margin-top: $base-margin;
}

.link-block {
	display: block;
	line-height: 1.2;
	text-decoration: none;
	border: 1px solid #000000;
}

.col-main {
	margin-left: auto;
	margin-right: auto;
}

// Posts list

.post-list {
	&__wrap {
		padding: $grid-gutter-width * 0.5 0 $grid-gutter-width * 0.5 $grid-gutter-width * 0.5;
		border-left: 4px solid #000000;
	}

	&__link {
		display: inline-block;
		line-height: (26 * 0.05);
		@include pwd-fontsize(20);
	}

	&--2 .post-list__link {
		font-weight: 500;
	}
}

// Single post

.post-meta,
.post-single__footer-description {
	line-height: 1.2;
}

.post-meta {
	display: block;
	font-weight: 600;

	time:not(:last-child) {
		margin-right: 5px;
	}

	.post-list--2 & {
		margin-bottom: 10px;
	}
}

.post-single {
	&__footer {
		padding-top: $base-margin;
		border-top: 1px solid $base-ruler-color;
	}

	&__footer-description {
		margin-left: 0;

		&:not(:last-child) {
			margin-bottom: 5px;
		}
	}
}

// Search form

[role="search"] input {
	margin-bottom: 5px;
}

@media screen {
	// Common

	[class*="padding-top--"] {
		padding-top: $base-margin;
	}

	[class*="padding-bottom"] {
		padding-bottom: $base-margin;
	}

	.theme-color--1 {
		color: $theme-color-1;
	}

	.theme-color--2 {
		color: $theme-color-2;
	}

	.theme-color--4 {
		color: $theme-color-4;
	}

	.theme-bg--3 {
		background-color: $theme-color-3;
	}

	.theme-bg--4 {
		background-color: $theme-color-4;
	}

	.link-block {
		border: 0;
	}

	// Post lists

	.post-list__wrap--color- {
		&1 {
			border-left-color: $theme-color-1;
		}

		&2 {
			border-left-color: $theme-color-2;
		}

		&4 {
			border-left-color: $theme-color-4;
		}
	}

	// Single post

	.post-meta {
		color: #4c4c4c;
	}

	.post-single__excerpt {
		border-left-color: $theme-color-4;
	}
}

@include media-breakpoint-up(sm) {
}

@media only print, screen and (min-width: map-get($grid-breakpoints, sm)) {
	// Single post

	.post-single__footer-term,
	.post-single__footer-description {
		display: inline-block;
	}

	.post-single {
		&__footer-description-list {
			margin-bottom: 5px;
		}

		&__footer-term {
			margin-right: 10px;
		}

		&__footer-description {
			margin-left: 0;

			&:not(:last-child) {
				margin-right: 3px;
				margin-bottom: 0;
				padding-right: 7px;
				border-right: 1px solid $base-text-color;
			}
		}
	}
}

@include media-breakpoint-up(md) {
}

@media screen and (min-width: map-get($grid-breakpoints, md)) {
	// Common

	.order-md-2 {
		order: 2;
	}
}

@media only print, screen and (min-width: map-get($grid-breakpoints, md)) {
}

@include media-breakpoint-up(lg) {
	// Common

	.margin-bottom--medium {
		margin-bottom: $base-margin-md;
	}

	.margin-bottom--large {
		margin-bottom: $base-margin-lg;
	}

	.margin-top--medium {
		margin-top: $base-margin-md;
	}
}

@media screen and (min-width: map-get($grid-breakpoints, lg)) {
	.padding-top-md--medium {
		padding-top: $base-margin-md;
	}

	.padding-top--large {
		padding-top: $base-margin-lg;
	}
}

@include media-breakpoint-up(xl) {
}
