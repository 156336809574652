// == PWD WP Sass Framework ==
// Shortcut link styles
// Template: templates/links-shortcut.php

@mixin links-shortcut {
	$padding: 10px;

	.links-shortcut {
		width: 100%;
		max-width: 280px;

		&__link {
			padding: $padding;
			font-weight: bold;
		}

		&__link:not(:last-child) {
			margin-bottom: 5px;
		}

		&__link--2 {
			font-weight: 500;
			border-bottom-left-radius: 15px;
		}

		&__link--has-icon {
			position: relative;
			min-height: 30px;
			padding-left: $padding + 37px;
		}

		&__icon {
			position: absolute;
			left: 17px;
			top: $padding - 2px;
		}
	}

	@media screen {
		.links-shortcut__link {
			color: #ffffff;
		}
	}

	@media only print, screen and (min-width: map-get($grid-breakpoints, lg)) {
		$padding: 16px;

		.links-shortcut {
			&__link {
				padding: $padding;
			}

			&__link--has-icon {
				padding-left: $padding + 37px;
			}

			&__icon {
				top: $padding - 2px;
			}
		}
	}
}
